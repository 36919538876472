<template>
	<div class="index-container">
		<!-- 选项 -->
		<el-card >
			<h4 class="font font-weight-normal m-0 text-black">
				学期
				<div class="d-inline-block ml-1" style="width: 200px;">
					<el-select placeholder="请选择学年" size="medium" class="w-100" v-model="s_id" @change="changeSemester">
						<el-option :value="item.id" :label="item.year + item.tag" v-for="(item,index) in semester_list" :key="item.id"/>
					</el-select>
				</div>
			</h4>
		</el-card>
		
		<el-row :gutter="20" class="mt-1">
			<!-- 资源概况 -->
			<el-col :span="24">
				<el-card>
					<el-row :gutter="20" type="flex">
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #6777EF;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.semester_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">期</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									学期总数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #409EFF;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.teacher_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">人</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									教师总数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #67C23A;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.kh_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">项</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									考核总项
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #51E3C0;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.wc_kh_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">项</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									已完成项
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #E6A23C;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.unwc_kh_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">项</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									未完成项
								</div>
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		
		<el-row :gutter="20" class="mt-2">
			<!-- 性别占比 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>性别占比
					</div>
					<div class="w-100 flex-all">
						<div ref="chart1" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
			<!-- 职称占比 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>职称占比
					</div>
					<div class="w-100 flex-all">
						<div ref="chart2" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		
		<el-row :gutter="20" class="mt-2">
			<!-- 学生获奖 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex justify-between align-center isCell">
						<div class="w-100 flex align-center fs_20">
							<span class="left-flag"></span>学生获奖
						</div>
					</div>
					<div class="tab_content" style="padding: 20px">
						<el-table :data="xshj_list.list" size="small" highlight-current-row height="300">
							<el-table-column prop="t_name" label="教师" align="center"></el-table-column>
							<el-table-column prop="xueke" label="学科" align="center"></el-table-column>
							<el-table-column prop="title" label="获奖名称" align="center"></el-table-column>
						</el-table>
					</div>
				</el-card>
			</el-col>
			<!-- 竞赛获奖 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex justify-between align-center isCell">
						<div class="w-100 flex align-center fs_20">
							<span class="left-flag"></span>竞赛获奖
						</div>
					</div>
					<div class="tab_content" style="padding: 20px">
						<el-table :data="jshj_list.list" size="small" highlight-current-row height="300">
							<el-table-column prop="t_name" label="姓名" align="center"></el-table-column>
							<el-table-column prop="xueke" label="学科" align="center"></el-table-column>
							<el-table-column prop="title" label="获奖名称" min-width="100" align="center"></el-table-column>
						</el-table>
					</div>
				</el-card>
			</el-col>
		</el-row>

		
		
		
		
	</div>
</template>

<script>
import * as echarts from "echarts"
	export default {
		name: 'Index',
		data () {
			return {
				s_id:'',
				year:'',
				semester_list:{},
                information:{},
				kh_result:[],
				xshj_list:[],
				jshj_list:[],
				chart1: {
				  tooltip: {
					trigger: 'item',
					formatter: "{c}人"
				  },
				  legend: {
					orient: 'vertical',
					left: 'left'
				  },
				  series: [
					{
					  type: 'pie',
					  radius: ['65%', '90%'],
					  left: 20,
					  label: {
						  show: false,
						  position: 'center'
					  },
					  data: [],
					  emphasis: {
						label: {
						  show: true,
						  fontSize: 22,
						  fontWeight: 'bold',
						  formatter: "{c}人\n{b}"
						}
					  }
					}
				  ]
				},
				chart2: {
				  tooltip: {
					trigger: 'item',
					formatter: "{c}人"
				  },
				  legend: {
					orient: 'vertical',
					left: 'left'
				  },
				  series: [
					{
					  type: 'pie',
					  radius: ['65%', '90%'],
					  left: 20,
					  label: {
						  show: false,
						  position: 'center'
					  },
					  data: [],
					  emphasis: {
						label: {
						  show: true,
						  fontSize: 22,
						  fontWeight: 'bold',
						  formatter: "{c}人\n{b}"
						}
					  }
					}
				  ]
				},
			}
		},
		mounted(){
			this.getsemester()
			this.teacherSexRatio()
			this.teacherZcRatio()
			this.getXshj()
			this.getJshj()
		},
		methods:{
			teacherSexRatio(){
				this.axios.get('/manage/teacher_sex_ratio').then(res=>{
					if(res.status){
						if(res.data.length > 0){
							let data = []
							if(res.data.length > 0){
								res.data.forEach((v)=>{
									data.push({ value: v.num, name: v.name })
								})
							}
							this.chart1.series[0].data = data
						}
					}
					this.drawLine1()
				})
			},
            teacherZcRatio(){
            	this.axios.get('/manage/teacher_zc_ratio').then(res=>{
            		if(res.status){
            			if(res.data.length > 0){
            				let data = []
            				if(res.data.length > 0){
            					res.data.forEach((v)=>{
            						data.push({ value: v.num, name: v.name })
            					})
            				}
            				this.chart2.series[0].data = data
            			}
            		}
            		this.drawLine2()
            	})
            },
			getXshj(){
				this.axios.get('/manage/xshjList',{
				   params:{
					   s_id:this.s_id,
					   limit:5,
				   }
			   }).then(res=>{
				   if(res.status){
					  this.xshj_list = res.data
					}
				})
			},
			getJshj(){
				this.axios.get('/manage/jsList',{
				   params:{
					   s_id:this.s_id,
					   limit:6,
				   }
			   }).then(res=>{
				   if(res.status){
					  this.jshj_list = res.data
					}
				})
			},
			getKhResultAsc(){
				this.axios.get('/manage/semester/view_asc',{
				   params:{
					   id:this.s_id
				   }
			   }).then(res=>{
				   if(res.status){
					  this.kh_result = res.data
					}
				})
			},
			changeSemester(e){
				this.s_id = e
				this.getInformation()
				this.getXshj()
				this.getJshj()
			},
			getsemester(){
				this.axios.get('/manage/semester/all').then(res=>{
					if(res.status){
						this.semester_list = res.data
						if(res.data.length > 0){
							this.year = res.data[0].year + ' ' + res.data[0].tag
							this.s_id = res.data[0].id
							this.getInformation()
							this.getXshj()
							this.getJshj()
						}
					}
				})
			},
            getInformation(){
			   this.axios.get('/manage/information',{
				   params:{
					   id:this.s_id
				   }
			   }).then(res=>{
				 this.information = res.data
			   })
            },
			drawLine1() {
				 // 初始化echarts实例
				 let myChart = echarts.init(this.$refs.chart1)
				 // 配置参数
				 myChart.setOption(this.chart1)
				 //默认选中第一项
				var index = 0
				myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
				//鼠标移入事件      
				myChart.on('mouseover',function(e){	
					if(e.dataIndex != index){
						myChart.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: index  });
					}		
				});
				//鼠标移出事件
				myChart.on('mouseout',function(e){		
				   index = e.dataIndex;
				   myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: e.dataIndex});	
				});
			},
			drawLine2() {
				 // 初始化echarts实例
				 let myChart = echarts.init(this.$refs.chart2)
				 // 配置参数
				 myChart.setOption(this.chart2)
				 //默认选中第一项
				var index = 0
				myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
				//鼠标移入事件      
				myChart.on('mouseover',function(e){	
					if(e.dataIndex != index){
						myChart.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: index  });
					}		
				});
				//鼠标移出事件
				myChart.on('mouseout',function(e){		
				   index = e.dataIndex;
				   myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: e.dataIndex});	
				});
			},
		  
		},
}
</script>

<style>
.left-flag{
	width: 17px;
	height: 16px;
	margin-right: 10px;
	/* background: #018EED; */
	background: url('~@/assets/images/icon06.png') no-repeat;
	background-size: cover;
}
</style>